export default {
  data() {
    return {
      doublonInfos: {
        sameCommandeInfo: {},
        duplicateDevisInfos: {},
        duplicateCommandeInfos: {},
      },
    };
  },
  methods: {
    /**
     * Affiche la modale de doublon de référence numerique
     */
    handleDuplicateModalToOpen(data) {
      if (
        data.same_commande_info !== undefined
        || data.duplicate_commande_infos !== undefined
        || data.duplicate_devis_infos !== undefined
      ) {
        this.doublonInfos.sameCommandeInfo = data.same_commande_info;
        this.doublonInfos.duplicateCommandeInfos = data.duplicate_commande_infos;
        this.doublonInfos.duplicateDevisInfos = data.duplicate_devis_infos;

        this.$nextTick(() => {
          this.$modal.show("modal_duplicate_devis_commande");
        });
        return true;
      }
      return false;
    },
  },
};
